import _ from 'underscore'
import Backbone from 'backbone'

import {
    extractContactAvatar,
    formatDate,
    formatPhone,
    parseDate,
    profileImageSrc,
    splitTags,
} from 'util/twistle'

import { localize } from 'util/localize'
import { timeAgoInWords } from 'util/date'
import { nullifyBlankObject } from "util/core"

// Model encapsulating a Contact
const Contact = Backbone.Model.extend({
    url: function() {
        return "/account/View?foruser=" + this.get("username");
    },

    parse: function(resp) {
        return resp.account || resp;
    },

    initialize: function() {
        this.bind('change', this.updateAttributes);
        this.updateAttributes();
    },

    updateAttributes: function() {
        var self = this;
        var orgCount = (App.account.get('orgs') && App.account.get('orgs').length) || 0;

        self.attributes.isDirectlyConnected = false;
        self.attributes.isTeamMember = false;
        self.attributes.isPatient = false;
        self.attributes.canAddToCircles = self.attributes.accounttype !== "s";
        self.attributes.network_list = [];
        self.attributes.tagInfo = splitTags(self.attributes.tags);
        self.attributes.bestAvailableDOB = nullifyBlankObject(self.attributes.dob) || nullifyBlankObject(self.attributes.org_dob);
        self.attributes.avatar = extractContactAvatar(self.attributes);
        self.attributes.formattedPhone = formatPhone(self.attributes.phone);
        (self.attributes.custodians || []).forEach(c => {
            c.formattedCustodianPhone =  formatPhone(c.custodian_phone);
        });
        // sort relationships based on type
        if (self.attributes.relationships_info){
            self.attributes.relationships_info.sort(self.relationshipComparator);
        }

        // and augment their props
        _.each(self.attributes.relationships_info, function(relationshipInfo, idx) {
            let organizationId = relationshipInfo.thru_organization,
                relationshipOrg = organizationId ? App.account.getOrgById(organizationId) : false,
                ts = relationshipInfo.initiated && parseDate(relationshipInfo.initiated);

            if(ts)
            {
                relationshipInfo.initatedDate = timeAgoInWords(ts);
            }

            relationshipInfo.idx = idx;

            relationshipInfo.relationship_label = self.formatRelationship(
                relationshipInfo.relationship,
                organizationId,
                relationshipInfo.thru_username,
                undefined,
                self.attributes.accounttype,
                relationshipInfo.organization_member_count,
                relationshipInfo.role
            );

            // Hack to show the org name for staff members
            if (relationshipInfo.relationship === "org-member") {
                relationshipInfo.organization_display = relationshipOrg.name;
                relationshipInfo.thru_organization_name = relationshipInfo.organization_display;
                relationshipInfo.organization_admin = relationshipOrg.admin_control_membership && relationshipInfo.admin; // show they are an admin if admin controls are enabled
            }
            relationshipInfo.displayRelationship = true;
            if(relationshipInfo.thru_username === "self"){
                relationshipInfo.thru_formalname = App.account.get("formalname");
                relationshipInfo.relationshipClass = relationshipInfo.relationship.replace(/[^a-zA-Z0-9]/g, "_");

                if(relationshipInfo.relationship === "org-member"){
                    self.attributes.isTeamMember = true;
                    self.attributes.teamMemberOrg = relationshipInfo.thru_organization;
                    // if they are an admin of this particular org, show as "directly connected" (which causes contact detail->actions to appear)
                    if(relationshipOrg && relationshipOrg.admin && self.attributes.accounttype !== "s"){
                        relationshipInfo.canRemove = true;
                        self.attributes.isDirectlyConnected = true;
                    }
                }
                else{
                    if(relationshipOrg && relationshipOrg.admin){
                        relationshipInfo.canRemove = true;
                    }
                    self.attributes.isDirectlyConnected = true;
                }

            }

            if((!relationshipInfo.organization || relationshipInfo.organization === "")  &&
                relationshipInfo.thru_organization && App.account.getOrgById(relationshipInfo.thru_organization) && orgCount > 1){
                relationshipInfo.organization_display = App.account.getOrgById(relationshipInfo.thru_organization).name.substring(0,11);
                if(relationshipInfo.organization_display.length === 11){
                    relationshipInfo.organization_display = relationshipInfo.organization_display.substring(0,10) + "...";
                }
            }
            if(relationshipInfo.relationship === "care provider" &&
                self.attributes.organization &&
                self.attributes.organization.name){
                relationshipInfo.organization_display = self.attributes.organization.name;
                relationshipInfo.canRemove = false;
            }

            if(relationshipInfo.relationship === "patient"){
                self.attributes.isPatient = true;
                if (relationshipInfo.thru_username === "self"){
                    self.attributes.thru_display = false;
                }
                else{
                    self.attributes.thru_display = true;
                    // you can remove a pt if you are an admin of the org they are connected thru
                    var ptOrg = App.account.getOrgById(relationshipInfo.thru_organization);
                    if(ptOrg && ptOrg.admin){
                        relationshipInfo.canRemove = true;
                    }
                }
            }

            if(relationshipInfo.relationship === "teammember"){
                relationshipInfo.relationship_label = relationshipInfo.thru_formalname;
            }

            if(relationshipInfo.relationship === "network"){
                relationshipInfo.is_network = true;
                if(relationshipInfo.thru_username && relationshipInfo.thru_username.length){
                    self.attributes.network = relationshipInfo;
                    self.attributes.network_list.push(relationshipInfo);
                    self.attributes.network_count = (self.attributes.network_count + 1) || 0;
                }
            }
            else{
                self.attributes.non_network = true;
            }

            if (!self.attributes.accepted && self.attributes.accounttype === "u") {
                if ((self.attributes.provisional || !relationshipInfo.accepted) && relationshipInfo.initiated && relationshipInfo.originator && !relationshipInfo.declined){
                    self.attributes.pendingAccept = true;
                    if(ts && relationshipInfo.initatedDate)
                    {
                        if (!self.attributes.pendingDateTS || self.attributes.pendingDateTS < ts)
                        {
                            self.attributes.pendingDateTS = ts;
                            self.attributes.pendingDate = relationshipInfo.initatedDate;
                        }
                    }
                }
            }

        });

        self.attributes.workflow_count = (self.attributes.workflow_executions &&
                                          self.attributes.workflow_executions.length);

        self.attributes.accountIsSelf = self.get("username") === App.account.get("username");
        self.attributes.accountIsGroup = _.contains(["g","r"], self.get("accounttype"));
        self.attributes.accountIsRole = self.get("accounttype") === "r";

        var baseProfileImageParams = {
            username: this.get("username"),
            seqnum: (this.attributes.photo || false),
            id: (this.attributes.id || false),
            accountType: this.attributes.accounttype
        };

        var thumbParams = _.clone(baseProfileImageParams);
        thumbParams.size = self.attributes.thumbsize || "thm";

        var prfParams = _.clone(baseProfileImageParams);
        prfParams.size = "prf";

        self.attributes.profileimageurl = profileImageSrc(thumbParams);
        self.attributes.profilefullimageurl = profileImageSrc(prfParams);

        if(self.attributes.org_dob_mismatch === true && self.attributes.org_dob)
        {
            self.attributes.org_dob_mismatch = self.attributes.org_dob;
        }

        self.attributes.org_editable_dob = self.attributes.org_dob_mismatch || self.attributes.dob;

        function addOptOut(optOutInfo, custodianInfo){
            if(optOutInfo.organization_id && optOutInfo.organization_id !== App.account.getDefaultOrg()?.id){
                return;
            }
            let phoneUserName = custodianInfo ? `${custodianInfo.custodian_formalname}` : self.attributes.formalname;
            self.attributes.show_delivery_warning = true;
            self.attributes.delivery_warning_info = `${phoneUserName} has opted out of SMS notifications from ${App.account.getOrgById(optOutInfo.organization_id).name} (${formatDate(optOutInfo.timestamp)}). `;
            self.attributes.delivery_warning_link_text = "Click here for instructions as to how to re-enable SMS notifications.";
            self.attributes.delivery_warning_alert_content = `Tell ${phoneUserName} to send a text message ${formatPhone(optOutInfo.to_phone)} with the word \n\n START \n\n to receive messages again.`;
        }

        self.attributes.sms_opt_out_info?.map(o => addOptOut(o));
        self.attributes.custodians?.map(c => c.sms_opt_out_info?.map(o => addOptOut(o, c)));

        function addDeliveryFailure(failureInfo, custodianInfo){
            if(App.account.hideSMSDeliveryWarnings() ||
                ["failed", "undelivered", "sending_failed", "delivery_failed"].indexOf(
                    failureInfo.delivery_status) === -1 ||
                (failureInfo.phone !== self.attributes.phone &&
                (custodianInfo && failureInfo.phone !== custodianInfo.custodian_phone))
            ){
                return;
            }
            let phoneUserName = custodianInfo ? `${custodianInfo.custodian_formalname}` : self.attributes.formalname;
            self.attributes.show_delivery_warning = true;
            self.attributes.delivery_warning_info = `${phoneUserName}'s SMS number ${formatPhone(failureInfo.phone)} has been unable to receive notifications from Twistle (on ${formatDate(failureInfo.timestamp)}). Please check the phone number, and contact Twistle support if more assistance is required.`;
            self.attributes.delivery_warning_link_text = undefined;
            self.attributes.delivery_warning_alert_content = undefined;
        }

        self.attributes.sms_delivery_info?.map(o => addDeliveryFailure(o));
        self.attributes.custodians?.map(c => c.sms_delivery_info?.map(o => addDeliveryFailure(o, c)));

    },

    formatRelationship: function(relationship, organizationId, thruUsername, invite_name, accounttype, member_count, role) {
        var self = this;
        var relationshipOrg = organizationId ? App.account.getOrgById(organizationId) : false;

        switch (relationship){
            case "org-member":
                if (accounttype === "s"){
                    return member_count + ((relationshipOrg && relationshipOrg.has_clients)?" Staff":"") + " Members";
                }
                if (role == "certified_builder") {
                    return "Certified Builder";
                }
                return (relationshipOrg && relationshipOrg.has_clients)?"Staff":relationshipOrg.name;
            case "network":
                return "via";
            case "teammember":
                return "";
            case "professional":
                return "Private Contact";
            case "family":
                return "Family";
            case "custodee":
                return self.get("invite_name") || localize("familyCaregiver", "Family Care Giver");
            case "custodian":
                return self.get("invite_name") || localize("familyCaregiver", "Family Care Giver");
            case "care provider":
                return localize("healthCareProvider", "Healthcare Provider");
            case "patient":
                return ((thruUsername==="self")? "My ": "" ) + "Patient";
        }

        return relationship;

    },

    relationshipComparator: function(r1,r2){
        // sort function for putting the relationship_info items in order of preference (some times the UI only displays the first one...)
        var relationshipOrder = ["teammember", "org-member", "custodian", "custodee", "family", "professional", "patient", "care provider"];
        return _.indexOf(relationshipOrder, r2.relationship) - _.indexOf(relationshipOrder, r1.relationship);
    },

    hasRelationship:function(r)
    {
        return _.indexOf(this.attributes.relationships,r) > -1;
    },

    relationshipInContextOfOrgId: function (orgId) {
        var self = this, r = _.findWhere(self.attributes.relationships_info, {thru_organization: orgId});
        return r && r.relationship;
    },

    hasRelationshipInContextOfOrgId:function(r,orgId)
    {
        var self = this;
        return _.some(self.attributes.relationships_info, function (relationshipInfo) {
            if(relationshipInfo.relationship === r)
            {
                if(orgId)
                {
                    if(relationshipInfo.thru_organization === orgId)
                    {
                        return true;
                    }
                }
                else
                {
                    return true;
                }
            }
            return false;
        });
    },

    // return true if this account is a healthcare provider
    isProvider: function() {
        return this.get('primaryroles') && this.get('primaryroles').indexOf('c') > -1;
    },

    // return true if this account is a patient
    isPatient: function() {
        if (this.get("primaryroles")) {
            return this.get("primaryroles").indexOf("p") > -1;
        }
        return this.getInviteInfo().invite_relationship === "patient";
    },

    // Returns true if this account has a custodian (caregiver) associated with it.
    hasCustodian: function() {
        const self = this;
        const custodians = self.get("custodians");
        return custodians && custodians.length > 0;
    },

    refreshPhoto: function() {
        var self = this;

        self.attributes.profileimageurl = profileImageSrc({ username: this.get("username"), size: "thm" }) + "&rnd=" + new Date().getTime();
        self.attributes.profilefullimageurl = profileImageSrc({ username: this.get("username"), size: "prf" }) + "&rnd=" + new Date().getTime();

        // trigger the profile pic update
        App.trigger("app:profile_photo_updated", this.get("username"), this.get("photo"));
    },

    isMemberOfOrg: function(orgId){
        var self = this;
        return _.any(self.attributes.relationships_info,function(r){return r.relationship==="org-member" && r.thru_organization ===orgId;});
    },

    getInviteInfo: function(){
        var self = this, result = {};
         _.each(self.attributes.relationships_info, function(val){
            if(val.thru_username === "self"){
                result.invite_relationship = val.relationship;
                result.invite_is_custodian = val.is_custodian_invite;
                result.invite_name = self.attributes.invite_name;
                if(val.thru_organization){
                    result.invite_organization = {id:val.thru_organization};
            }
            // go pull the invite message, if there is one...
            if(val.invite_conversation_id){
                result.inviteConversation = new App.Conversation({id:val.invite_conversation_id});
            }
            return false;
            }
        });
        return result;
    },

    // return formalname and dob if it is available
    nameAndDob: function() {
        var name = this.get("formalname");
        var dob = this.get("dob");
        if (dob) {
            return name + " (" + dob + ")";
        }
        return name;
    }

});

export default Contact;
